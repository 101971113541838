import { gsap, Expo } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

export function coreSp() {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);
  const body = document.body;
  const button = document.querySelector(".header__button");
  let tl2 = gsap.timeline({ paused: true });

  ScrollTrigger.create({
    trigger: ".js-trigger",
    start: "top top",
    end: +10000,
    toggleClass: { targets: body, className: "is-active" }, //クラスをつけたり、外したりできる
  });

  ScrollTrigger.create({
    trigger: ".js-trigger-2",
    start: "top top",
    end: +10000,

    toggleClass: { targets: body, className: "is-active-2" }, //クラスをつけたり、外したりできる
  });

  tl2.to(".menu", {
    duration: 0.8,
    scaleX: 1,
    ease: Expo.easeInOut,
  });
  tl2.from(".menu__item", {
    duration: 0.4,
    stagger: 0.1,
    y: 20,
    opacity: 0,
  });
  tl2.reverse();

  function buttonStatus() {
    if (button.classList.contains("is-active")) {
      gsap.to(".button", {
        filter: "brightness(100%)",
        delay: 0.2,
      });
      gsap.to(".logo_4", {
        fill: "#fff",
        delay: 0.2,
      });
    } else {
      gsap.to(".button", {
        filter: "",
        delay: 0.2,
      });
      gsap.to(".logo_4", {
        fill: "",
        delay: 0.2,
      });
    }
  }
  button.addEventListener("click", () => {
    button.classList.toggle("is-active");
    buttonStatus();
    tl2.reversed(!tl2.reversed());
  });

  const menus = document.querySelectorAll(".js-menu");
  const sections = document.querySelectorAll("section");
  menus.forEach((menu, i) => {
    menu.addEventListener("click", () => {
      const moveSize =
        window.pageYOffset + sections[i].getBoundingClientRect().top;
      window.scrollTo({
        top: moveSize,
        behavior: "smooth",
      });
    });
  });
}
