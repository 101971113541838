export function hover() {
  const hoverItems = document.querySelectorAll(".js-hover");
  hoverItems.forEach((el) => {
    el.addEventListener("mouseenter", () => {
      el.classList.add("is-active");
    });
    el.addEventListener("mouseleave", () => {
      el.classList.remove("is-active");
    });
  });
}
