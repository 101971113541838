import { gsap, Expo } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

export function core() {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);
  const wrapper = document.querySelector(".wrapper");
  const sections = gsap.utils.toArray("section");
  const nav = gsap.utils.toArray(".js-nav");
  const menu = gsap.utils.toArray(".js-menu");
  const body = document.body;
  const button = document.querySelector(".header__button");
  let getMaxWidth = () =>
    sections.reduce((val, section) => val + section.offsetWidth, 0);
  let maxWidth = getMaxWidth();
  let scrollSpeed = 2;
  let lastScrollTween = 0;
  let snapProgress;
  let tl = gsap.timeline();
  let tl2 = gsap.timeline({ paused: true });

  tl.to(sections, {
    x: () => window.innerWidth - maxWidth,
    duration: 1,
    ease: "none",
  });

  tl2.to(".menu", {
    duration: 0.8,
    scaleX: 1,
    ease: Expo.easeInOut,
  });
  tl2.from(".menu__item", {
    duration: 0.4,
    stagger: 0.1,
    y: 20,
    opacity: 0,
  });
  tl2.reverse();

  ScrollTrigger.create({
    animation: tl,
    trigger: wrapper,
    pin: true,
    scrub: 1,
    end: () => "+=" + maxWidth / scrollSpeed,
    invalidateOnRefresh: true,
  });

  function buttonStatus() {
    if (button.classList.contains("is-active")) {
      gsap.to(".button", {
        filter: "brightness(100%)",
        delay: 0.2,
      });
      gsap.to(".logo_4", {
        fill: "#fff",
        delay: 0.2,
      });
    } else {
      gsap.to(".button", {
        filter: "",
        delay: 0.2,
      });
      gsap.to(".logo_4", {
        fill: "",
        delay: 0.2,
      });
    }
  }
  function init() {
    gsap.set(sections, { x: 0 });
    maxWidth = getMaxWidth();
    let position = 0;
    let distance = maxWidth - window.innerWidth;
    tl.add("label0", 0);
    sections.forEach((section, i) => {
      let progress = position;
      position += section.offsetWidth / distance;
      tl.add("label" + (i + 1), position);
      nav[i].onclick = () => {
        snapProgress = progress;
        lastScrollTween = Date.now();
        gsap.to(window, {
          scrollTo: (maxWidth / scrollSpeed) * progress,
          duration: 1,
          overwrite: "auto",
        });
      };
      menu[i].onclick = () => {
        snapProgress = progress;
        lastScrollTween = Date.now();
        gsap.to(window, {
          scrollTo: (maxWidth / scrollSpeed) * progress,
          duration: 1,
          overwrite: "auto",
        });
        button.classList.toggle("is-active");
        buttonStatus();
        tl2.reversed(!tl2.reversed());
      };
    });
    button.addEventListener("click", () => {
      button.classList.toggle("is-active");
      buttonStatus();
      tl2.reversed(!tl2.reversed());
    });
  }

  init();
  ScrollTrigger.addEventListener("refreshInit", init);
}
