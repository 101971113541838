export function spanWrap(el) {
  const target = el;
  const nodes = [...target.childNodes];
  let spanWrapText = "";
  nodes.forEach((node) => {
    if (node.nodeType == 3) {
      const text = node.textContent.replace(/\r?\n/g, "");
      spanWrapText =
        spanWrapText +
        text.split("").reduce((acc, v) => {
          return acc + `<span class="js-fade">${v}</span>`;
        }, "");
    } else {
      spanWrapText = spanWrapText + node.outerHTML;
    }
  });
  target.innerHTML = spanWrapText;
}
