import { gsap, Expo } from "gsap";

export function loading() {
  function no_scroll() {
    document.addEventListener("mousewheel", scroll_control, { passive: false });
    document.addEventListener("touchmove", scroll_control, { passive: false });
  }
  function return_scroll() {
    document.removeEventListener("mousewheel", scroll_control, {
      passive: false,
    });
    document.removeEventListener("touchmove", scroll_control, {
      passive: false,
    });
  }
  function scroll_control(event) {
    event.preventDefault();
  }
  no_scroll();

  const gif = document.querySelector(".loading__item");
  gif.src = "img/logo.gif";

  const tl = gsap.timeline();
  tl.to(".loading", {
    opacity: 0,
    duration: 1,
    delay: 2,
    display: "none",
  }),
    tl.from(".background", {
      scale: 1.05,
      opacity: 0,
      duration: 1,
    }),
    tl.from(".header", {
      y: 50,
      opacity: 0,
    }),
    tl.from(".navigate__line", {
      scaleX: 0,
    }),
    tl.from(
      ".navigate__item",
      {
        y: 50,
        opacity: 0,
        stagger: 0.05,
      },
      ">-0.25"
    ),
    tl.from(
      ".js-fade-single",
      {
        y: 100,
      },
      ">-0.5"
    ),
    tl.from(".js-fade", {
      y: 100,
      stagger: 0.05,
    }),
    tl.from(".hero__logo", {
      x: -80,
      opacity: 0,
      onComplete: () => {
        return_scroll();
      },
    });
}
