import { gsap, Expo } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function scrollAnimation() {
  const body = document.body;
  const sections = gsap.utils.toArray("section");
  let getMaxWidth = () =>
    sections.reduce((val, section) => val + section.offsetWidth, 0);
  let maxWidth = getMaxWidth();

  //TOP<--/-->CONTACT 間の背景画像の変更
  ScrollTrigger.create({
    start: window.innerWidth,
    onEnter: () => {
      gsap.to(".background__contact", {
        opacity: 1,
      });
    },
    onLeaveBack: () => {
      gsap.to(".background__contact", {
        opacity: 0,
      });
    },
  });
  //TOP-->ABOUT 以降時にロゴ&ナビを黒に
  ScrollTrigger.create({
    start: window.innerWidth / 2,
    end: maxWidth,
    onEnter: () => {
      body.classList.add("is-active");
    },
    onLeaveBack: () => {
      body.classList.remove("is-active");
    },
  });
  //COMPANY-->CONTACT 以降時にロゴ&ナビを白に
  ScrollTrigger.create({
    start: maxWidth - window.innerWidth - 3000,
    end: maxWidth,
    onEnter: () => {
      body.classList.add("is-active-2");
      body.classList.remove("is-active");
    },
    onLeaveBack: () => {
      body.classList.remove("is-active-2");
      body.classList.add("is-active");
    },
  });
  //タイトルの縦スクロール
  gsap.to(".service__lead", {
    y: -1200,
    scrollTrigger: {
      start: window.innerWidth - 800,
      end: maxWidth,
      scrub: 2,
    },
  });
  gsap.to(".company__lead", {
    y: -1200,
    scrollTrigger: {
      start: window.innerWidth,
      end: maxWidth,
      scrub: 2,
    },
  });
}
