import "../scss/main.scss";
import { core } from "./core";
import { hover } from "./hoverActive";
import { scrollAnimation } from "./scrollAnimation";
import { coreSp } from "./coreSp";
import { loading } from "./loading";
import { spanWrap } from "./spanWrap";

function initPage() {
  if (matchMedia("(min-width: 768px)").matches) {
    core();
    hover();
    scrollAnimation();
  } else {
    coreSp();
  }
  const target = document.querySelectorAll(".js-span-wrap-text");
  target.forEach((el) => spanWrap(el));
  loading();
}

window.addEventListener("load", () => {
  initPage();
});

window.addEventListener("resize", () => {
  let ua = navigator.userAgent;
  if (
    (ua.indexOf("iPhone") > 0 || ua.indexOf("Android") > 0) &&
    ua.indexOf("Mobile") > 0
  ) {
    return false;
  }
  if (!matchMedia("(min-width: 768px)").matches) {
    location.reload();
  }
});
